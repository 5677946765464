import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';
import CTA from './cta.js';

export default class WaysToIncreaseConversion extends Component {

    render() {

        return (
            <div class='container'>

                    <h1>
                        10 Ways To Improve Your Conversion Rate on Shopify.
                    </h1>

                        <p class="lead mb-4">
                        The key to converting customers online is by proving to them that you can deliver significantly more value to them than you are charging. So, the first question is: what’s valuable?
                        </p>

                        <p class="lead mb-4">
                        First off, what is valuable to people is achieving their desires/goals/needs/etc., but its even more valuable if its really likely that they will achieve those things, even more so if they can achieve it tomorrow instead of next week or next year and even more valuable if they don’t have to do anything, sacrifice anything or put any effort in to achieve it. Those are the ways to create value, but how do you showcase that online?
                        </p>

                        <h3>How To Increase Your Conversion Rate:</h3>

                        <h5>
                            <a href='/get_your_target_market_on_your_site/' target='_blank'>1. Get Your Target Market To Your Site</a>
                        </h5>
                        <p class="lead mb-4">
                        To share an obvious example, imagine you are selling jackets to stay warm in the winter, but all your website visitors live in the caribbean. Do some of them need warm jackets, probably, but mostly not. So, you might have some people buying, but not too many. You’ll want to make sure to find the audience that buys. Now, that being said, sometimes these audiences might surprise you.
                        </p>
                        <p class="lead mb-4">
                        CutCo is successful in selling knives to realtors. Seems like an odd match, but their knives will last long enough for a realtor to give it as a housewarming gift, and their clients use knives with their name on it for long enough to buy their next house with the same realtor. Stanley Drinkwear sold robust mugs to construction workers, and then moms started bedazzling them. Now Stanley also sells bright and colorful mugs for women.
                        </p>
                        <p class="lead mb-4">
                        I worked with a company that sold electronics to parents as an educational tool for their kids. Seems like the right market, but there was another market that converted more often and bought more, grandparents. Yet none of their marketing was for grandparents, they were missing an opportunity to improve their conversion rate by just bringing in the right people. 
                        </p>

                        <h5>
                            <a href='/know_your_target_markets_dream_outcome/' target='_blank'>2. Know Your Target Market's Dream Outcome</a>
                        </h5>

                        <p class="lead mb-4">
                        Are people buying your jacket because it’s warm, fashionable, both? You need to understand why your target market is buying your product so that when you work on all the supporting material, you know what story to tell. If everyone is buying your jacket to stay warm in freezing temperatures and you focus on how fashionable it is, they might leave because they aren’t confident that you actually deliver on keeping them warm.
                        </p>

                        <h5>
                            <a href='/product_descriptions_to_improve_your_conversion_rate/' target='_blank'>3. Text That Supports How You’ll Help Them</a>
                        </h5>

                        <p class="lead mb-4">
                        Keeping with the idea that your customer buys your jacket to stay warm, you’ll want to use text to tell your customer that it keeps them warm. Anything from telling them that your jacket is rated for negative temperatures to talking about how you can stand outside in the cold for an hour without discomfort. Use text to prove that they will get the result they want.
                        </p>

                        <h5>
                            <a href='/images_to_improve_your_conversion_rate/' target='_blank'>4. Images That Supports How You’ll Help Them</a>
                        </h5>

                        <p class="lead mb-4">
                        Then support that text with images. Use a picture of someone standing outside in a blizzard with a smile on their face. Or if you want to be funny, picture someone wearing your jacket on the beach sweating profusely. Usually you also want to try and represent your target market in a photo as well. So, if you sell to Canadians, pictures in Canada will work better. Show people themselves using your product and getting what they want.
                        </p>

                        <h5>
                            <a href='/videos_to_improve_your_conversion_rate/' target='_blank'>5. Videos That Supports How You’ll Help Them</a>
                        </h5>

                        <p class="lead mb-4">
                        Videos are similar to images, use them to show your product in action. You can use the same ideas in images and text, but with video and audio. Perhaps you have a video of someone wearing your jacket in a blizzard talking about how warm they are. You are just building up evidence that your product achieves their outcome, and video can be extremely convincing!
                        </p>

                        <h5>
                            <a href='/use_a_guarantee_to_improve_your_conversion_rate/' target='_blank'>6. Guarantees That You Will Help Them</a>
                        </h5>

                        <p class="lead mb-4">
                        Customers are giving you money, so they’d prefer you put skin in the game. Let’s imagine you were looking at two jackets. One says that it will keep you warm, the other says that if you ever feel cold in this jacket you get your money back. Which do you choose? One thing to watch out for: many people just say, “Satisfaction Guaranteed or Your Money Back.” That is a missed opportunity to connect with your audience. Broadly, they are looking to be satisfied, but in particular they want their dream outcome, so you’ll make more headway guaranteeing something particular.
                        </p>

                        <h5>
                            <a href='/reviews_to_improve_your_conversion_rate/' target='_blank'>7. Guarantees That You Will Help Them</a>
                        </h5>

                        <p class="lead mb-4">
                        You can showcase your own belief in your product with text, images, videos and guarantees, but does it work for other people? That is where reviews come into play. Are other people shouting your praises for making a product that gives them what they want? Once again, reviews can be text based, images or videos. Get good reviews and you’ll get more customers.
                        </p>

                        <h5>8. Proof That You Will Make It Easy</h5>

                        <p class="lead mb-4">
                        Do I need to assemble your product once I get it, or use it consistently for years, days, minutes? How hard is it to achieve? In terms of a jacket, there are some new jackets that are warm, but you have to charge them first. If I can get the same thing without needing to remember to plug it in, I’m going for that one.
                        </p>

                        <h5>9. Proof That You Will Make It Quick</h5>

                        <p class="lead mb-4">
                        Amazon has made an immense amount of money on making things quick. Think about how much they’ve spent on getting you products in a week, to a couple days to now a couple hours in a city. Speed matters, just look to amazon for proof.
                        </p>

                        <h5>
                            <a href='/reasons_to_buy_now_to_improve_your_conversion_rate/' target='_blank'>10. Guarantees That You Will Help Them</a>
                        </h5>
                        <p class="lead mb-4">
                        Reasons to buy now can range from sales to inventory to timing or anything else creative you can come up with. Maybe you only have 5 jackets left in stock, so if you don’t buy now, you may not get one or the 20% off sale only lasts until Sunday. Or simply winter is around the corner and you’ll definitely want one before you get cold.
                        </p>

                        <h3>3 Reasons That You Still Don't Have A Good Conversion Rate If You Have The Above</h3>

                        <h5>1. Surprise Extra Costs</h5>

                        <p class="lead mb-4">
                        Everything has gone well and someone wants to buy your product, but then all of a sudden they see they have to pay a monthly fee or shipping is super expensive for them. No one likes to be surprised by an un-expected cost and it will hurt your conversion rate. 
                        </p>

                        <h5>2. It's Difficult To Buy</h5>

                        <p class="lead mb-4">
                        Your customer gets to checkout and inputs their address, but you say you can’t ship to them. Or the design of your checkout is long and confusing. Make it easy to buy! 
                        </p>

                        <h5>3. They Forget About You</h5>

                        <p class="lead mb-4">
                        Maybe your customer was ready to order and their significant other came into the room to talk. They forget about you and never return. Cart abandonment emails and re-targeting ads help to make sure that they remember you can can purchase again.
                        </p>

                <CTA />
            </div>
        );
    }
}
