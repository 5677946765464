import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';

export default class CTA extends Component {
    constructor(props) {
        super(props);

        this.sign_up = this.sign_up.bind(this);
    }

    sign_up() {
        window.gtag('event', 'schedule_call');
        //console.log("Sign up event")

        window.location.href = 'https://calendly.com/jeremy-thiesen1/meetings'
    }

    render() {

        return (
            <div style={{marginTop:'50px', marginBottom:'50px'}}>
                

                <div class="col-lg-6 mx-auto">
                <h4>Taking The Steps To Improve Your Conversion Rate Can Be A Lot! Luckily, we are helping out 10 people increase their conversion rates for free (6 spots left). Schedule some time to talk with us to reserve your spot!
                </h4>
                    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                        
                    <Button
                                type="primary"
                                onClick={this.sign_up}
                                style={{ fontSize: '30px' }}
                            >
                                Schedule A Quick 30 Minute Call Today
                            </Button>
                    </div>
                </div>
            </div>
        );
    }
}
