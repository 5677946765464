import React, { Component } from 'react';
import $, { ajax } from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

class PropertyChoice extends Component {
    constructor(props) {
        super(props);

        this.choose_property = this.choose_property.bind(this);
    }

    choose_property() {
        ajax_wrapper(
            'POST',
            '/api/set_default_property/',
            {
                account_name: this.props.account_name,
                property_name: this.props.property.name,
                property_id: this.props.property.property_id,
            },
            () => (window.location.href = '/insights/'),
        );
    }

    render() {
        return (
            <tr>
                <td></td>
                <td>{this.props.property.name}</td>
                <td>
                    <Button type="primary" onClick={this.choose_property}>
                        Get The Insights Report
                    </Button>
                </td>
            </tr>
        );
    }
}

export default class AccountsAndProperties extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accounts_and_properties: [],
            loaded: false,
        };

        this.get_properties = this.get_properties.bind(this);
        this.load_properties = this.load_properties.bind(this);
    }

    componentDidMount() {
        if (
            window.secret_react_state.user.google_property_id.length > 0 &&
            !window.secret_react_state.user.is_staff
        ) {
            let params = get_global_state()['params'];
            if (!params['change_property']) {
                window.location.href = '/insights/';
            }
        }
        this.get_properties();
    }

    get_properties() {
        ajax_wrapper('GET', '/api/get_properties/', {}, this.load_properties);
    }

    load_properties(data) {
        data['loaded'] = true;

        if (
            data['accounts_and_properties'].length == 1 &&
            data['accounts_and_properties'][0]['property_list'].length == 1
        ) {
            window.location.href = '/insights/';
        }
        this.setState(data);
    }

    render() {
        let accounts_and_properties = [];
        for (let account of this.state.accounts_and_properties) {
            accounts_and_properties.push(
                <tr>
                    <th>{account.name}</th>
                    <td></td>
                    <td></td>
                </tr>,
            );

            for (let property of account['property_list']) {
                accounts_and_properties.push(
                    <PropertyChoice
                        property={property}
                        account_name={account.name}
                    />,
                );
            }
        }

        if (accounts_and_properties.length == 0) {
            return (
                <Loading loaded={this.state.loaded}>
                    <Container>
                        <h3>
                            It Looks Like You Don't Have A Google Analytics
                            Account.
                        </h3>
                        <p>
                            If you haven't set up shopify with google analytics,
                            try{' '}
                            <a href="https://help.shopify.com/en/manual/reports-and-analytics/google-analytics/google-analytics-setup">
                                this guide.
                            </a>
                        </p>
                        <p>
                            If you need permission to a google analytics
                            account: Ask someone who does to use{' '}
                            <a href="https://support.google.com/analytics/answer/9305788?hl=en#zippy=%2Cin-this-article">
                                this guide
                            </a>{' '}
                            and invite you.
                        </p>
                        <p>
                            If you accidentally logged in with the wrong google
                            account: <a href="/logout">Logout</a> and try again.
                        </p>
                    </Container>
                </Loading>
            );
        } else {
            return (
                <Loading loaded={this.state.loaded}>
                    <Container>
                        <h3>Choose The Account For Your Shopify Store</h3>
                        <div style={{ height: '50px' }}></div>
                        <table className="table">
                            <thead>
                                <th>Account Name</th>
                                <th>Property Name</th>
                                <th>Get The Report</th>
                            </thead>
                            <tbody>{accounts_and_properties}</tbody>
                        </table>
                    </Container>
                </Loading>
            );
        }
    }
}
