import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class InvestigateConversionIssue extends Component {
    constructor(props) {
        super(props);

        this.state = {
            insight: { opportunity_size: 0 },
            data: {},
            loaded: false,
        };

        this.get_insight = this.get_insight.bind(this);
        this.load_insight = this.load_insight.bind(this);
    }

    componentDidMount() {
        this.get_insight();
    }

    get_insight() {
        let params = get_global_state()['params'];
        let url = '';
        if (params['source']) {
            url = `/api/get_insight/?type=investigate_conversion_issue&item_id=${params['item_id']}&source=${params['source']}&medium=${params['medium']}`;
        } else {
            url = `/api/get_insight/?type=investigate_conversion_issue&item_id=${params['item_id']}&age=${params['userAgeBracket']}&gender=${params['userGender']}&region=${params['region']}`;
        }

        ajax_wrapper('GET', url, {}, this.load_insight);
    }

    load_insight(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    render() {
        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <div class="col-12 d-flex flex-column gap-2">
                        <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                            <svg class="bi" width="1em" height="1em"></svg>
                        </div>
                        <h4 class="fw-semibold mb-0 text-body-emphasis">
                            <Button type="dark">
                                Investigate Conversion Issue
                            </Button>
                        </h4>
                        <p class="text-body-secondary">
                            {this.state.insight.opportunity}
                        </p>
                        <p>
                            <b>Monthly Opportunity:</b> $
                            {this.state.insight.opportunity_size.toFixed(0)}
                        </p>
                    </div>

                    <h2>How To Help People Convert More Often</h2>
                    <div
                        style={{
                            paddingBottom: '20px',
                        }}
                    >
                        <iframe
                            src="https://www.loom.com/embed/3cafea4a887b4ec580bf4dec938f8760?sid=625d800a-c2a5-4ff4-8c1f-9e4f9701262a"
                            frameborder="0"
                            webkitallowfullscreen
                            mozallowfullscreen
                            allowfullscreen
                            style={{
                                width: '600px',
                                height: '314px',
                            }}
                        ></iframe>
                    </div>

                    <p>Two paths here:</p>
                    <p>
                        First, is there a reason why someone may not be able to
                        convert after adding something to their cart? Do you not
                        ship to their country? Do you have surprise/exhorbitant
                        shipping fees?
                    </p>
                    <p>
                        Secondly, you may need to keep building on the offer.
                        And you can do that through abandoned cart emails.
                    </p>
                    <ul>
                        <li>
                            Send a reminder about their cart and the offer that
                            includes their dream outcome, how you make it easy
                            to achieve it, why they can trust you and how you
                            guarantee it.
                        </li>
                        <li>
                            Create some urgency to buy. Maybe this item is going
                            away soon or increasing its price soon.
                        </li>
                        <li>Offer them a deal if they buy now!</li>
                    </ul>

                    <p>
                        Here is a list of top abandoned cart apps for shopify so
                        you can choose the best option for yourself.
                        <a href="https://www.omnisend.com/blog/abandoned-cart-shopify/">
                            10 best Shopify abandoned cart apps in 2024
                        </a>
                    </p>

                    <Button
                        type="success"
                        href="https://calendly.com/jeremy-thiesen1/meetings"
                        target="_blank"
                    >
                        Schedule Time With Us To Help
                    </Button>
                </Container>
            </Loading>
        );
    }
}
