import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper } from 'functions';

export default class Insights extends Component {
    constructor(props) {
        super(props);

        this.state = {
            marketing_insights: [],
            technical_design_insights: [],
            loaded: false,
            show_all: false,
        };

        this.get_insights = this.get_insights.bind(this);
        this.load_insights = this.load_insights.bind(this);
        this.toggle_show_all = this.toggle_show_all.bind(this);
    }

    componentDidMount() {
        this.get_insights();
    }

    toggle_show_all() {
        this.setState({ show_all: !this.state.show_all });
    }

    get_insights() {
        ajax_wrapper('GET', '/api/get_insights/', {}, this.load_insights);
    }

    load_insights(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    render() {
        let marketing_insights_list = [];
        for (let insight of this.state.marketing_insights) {
            let url = null;
            if (insight['source']) {
                url = `/${insight.opportunity_type}/?item_id=${insight.itemId}&source=${insight.source}&medium=${insight.medium}`;
            } else {
                url = `/${insight.opportunity_type}/?item_id=${insight.itemId}&userAgeBracket=${insight.userAgeBracket}&userGender=${insight.userGender}&region=${insight.region}`;
            }

            marketing_insights_list.push(
                <div class="col-12 d-flex flex-column gap-2">
                    <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                        <svg class="bi" width="1em" height="1em"></svg>
                    </div>
                    <h4 class="fw-semibold mb-0 text-body-emphasis">
                        <Button type="dark" href={url}>
                            {insight.opportunity_next_step}
                        </Button>
                    </h4>
                    <p class="text-body-secondary">{insight.opportunity}</p>
                    <p>
                        <b>Monthly Opportunity:</b> $
                        {insight.opportunity_size.toFixed(0)}
                    </p>
                </div>,
            );

            if (marketing_insights_list.length == 5 && !this.state.show_all) {
                break;
            } else if (marketing_insights_list.length == 30) {
                break;
            }
        }

        if (this.state.show_all) {
            marketing_insights_list.push(
                <a
                    onClick={this.toggle_show_all}
                    style={{ color: 'blue', textDecoration: 'underline' }}
                >
                    Show Less
                </a>,
            );
        } else {
            marketing_insights_list.push(
                <a
                    onClick={this.toggle_show_all}
                    style={{ color: 'blue', textDecoration: 'underline' }}
                >
                    Show All
                </a>,
            );
        }

        let technical_design_insights_list = [];
        for (let insight of this.state.technical_design_insights) {
            technical_design_insights_list.push(
                <div class="col-12 d-flex flex-column gap-2">
                    <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-4 rounded-3">
                        <svg class="bi" width="1em" height="1em"></svg>
                    </div>
                    <h4 class="fw-semibold mb-0 text-body-emphasis">
                        <Button
                            type="dark"
                            href={`/${insight.opportunity_type}/?deviceCategory=${insight.deviceCategory}&browser=${insight.browser}`}
                        >
                            {insight.opportunity_next_step}
                        </Button>
                    </h4>
                    <p class="text-body-secondary">{insight.opportunity}</p>
                    <p>
                        <b>Monthly Opportunity:</b> $
                        {insight.opportunity_size.toFixed(0)}
                    </p>
                </div>,
            );

            if (technical_design_insights_list.length == 5) {
                break;
            }
        }
        let technical_design_table = (
            <div>
                <h3>Technical/Design Insights</h3>
                <div class="row g-4">{technical_design_insights_list}</div>
            </div>
        );
        if (technical_design_insights_list.length == 0) {
            technical_design_table = (
                <div>
                    <h3>Technical/Design Insights</h3>
                    <p>
                        We found no insights, your site is performing as
                        expected. Well done!
                    </p>
                </div>
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <h3>Product Insights</h3>

                    <div class="row g-4">{marketing_insights_list}</div>
                    <div style={{ height: '50px' }}></div>
                    {technical_design_table}
                </Container>
            </Loading>
        );
    }
}
