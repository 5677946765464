import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';
import CTA from './cta.js';

export default class ReasonsToBuyNow extends Component {

    render() {

        return (
            <div class='container'>

                    <h1>
                        Increase Your Conversion Rate By Getting Your Target Market To Your Website
                    </h1>

                        <p class="lead mb-4">
                        Earlier, we talked about <a href='/ways_to_increase_conversion_rate/' target='_blank'>all the ways to increase your conversion rate.</a>
                        </p>

                        <p class="lead mb-4">
                        And now you are here to increase your conversion rate by giving customers a reason to buy now instead of later.
                       </p>

                       <p class="lead mb-4">
                       One of the issues of online ordering is that your customer could leave and come back any time, why should they buy now? The following are a couple of ways that you can create more urgency for your customers so they buy instead of leave.

                       </p>

                       <h5><strong>Limited Time Offers</strong></h5>

                       <p class="lead mb-4">
                       One of the most effective ways to create urgency is through limited-time offers. These can include:
                       </p>

                       <p class="lead mb-4">
                        <strong>Flash Sales:</strong> Announce a sale that lasts for only a few hours or days. Use countdown timers on product pages to highlight the urgency.
                       </p>

                       <p class="lead mb-4">
                        <strong>Seasonal Promotions:</strong> Leverage holidays or events to create themed sales that are only available for a short period. For example, a “Black Friday Countdown Sale” can encourage customers to act quickly.
                       </p>

                       <p class="lead mb-4">
                        <strong>Limited-time Coupons:</strong> Send out time-sensitive discount codes through email marketing or social media. For example, a coupon that expires in 24 hours can prompt immediate action.
                       </p>

                       <p class="lead mb-4">
                        <strong>Pre-Order Incentives:</strong> For upcoming products, offer exclusive pre-order pricing. This rewards customers for their commitment and creates urgency for those who want to be the first to own the product.
                       </p>

                       <h5><strong>Scarcity Tactics</strong></h5>

                       <p class="lead mb-4">
                       The principle of scarcity plays a significant role in consumer psychology. When customers perceive that a product is in limited supply, they are more likely to purchase it immediately. You can implement this by:

                       </p>

                       <p class="lead mb-4">
                        <strong>Showing Stock Levels:</strong> Display real-time inventory levels on product pages, such as “Only 3 left in stock!” This can motivate customers to buy before the product runs out.

                       </p>

                       <p class="lead mb-4">
                        <strong>Highlighting Popularity:</strong> Use phrases like “Best Seller” or “Trending Now” to signal to customers that other buyers are interested in these items and they might not be around forever.
                       </p>


                       <p class="lead mb-4">
                       For example, Zara, a clothing company always creates a limited availability of their styles. So, what you see will only be around for a limited time and you won’t be able to get it if you don’t buy now. In addition, it makes sure that each piece is unique and helps deliver on their own brand that you won’t be wearing the same thing as everyone else when something is popular.
                       </p>

                       <h5><strong>Scarcity Tactics</strong></h5>

                       <p class="lead mb-4">
                        Last, but not least, there are times when there are events in the world that make something a better buy now than later. 

                       </p>

                       <p class="lead mb-4">
                        <strong>Events:</strong> Something that is related to an event like the Olympics will have a time period built in. You need to buy it before the event starts or else it won’t have the same value. 
                       </p>

                       <p class="lead mb-4">
                       <strong>Seasons and Holidays:</strong> People will buy swimsuits before the summer and jackets before the winter, because they need it. You can remind customers to buy before the next season or else they will be un-prepared.
                       </p>

                       
                        <p>What makes sense for your product? What reasons do your customers have to buy now?</p>

                        

                <CTA />
            </div>
        );
    }
}
