import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';
import CTA from './cta.js';

export default class GetYourTargetMarketOnYourSite extends Component {

    render() {

        return (
            <div class='container'>

                    <h1>
                        Increase Your Conversion Rate By Getting Your Target Market To Your Website
                    </h1>

                        <p class="lead mb-4">
                        Earlier, we talked about <a href='/ways_to_increase_conversion_rate/' target='_blank'>all the ways to increase your conversion rate.</a>
                        </p>

                        <p class="lead mb-4">
                        And now you are here to increase your conversion rate by getting the right target market to your site. How do you do that?
                        </p>

                        <p class="lead mb-4">
                        First, we need to figure out who is buying your product and I like to start with analytics. You can use google analytics to get demographics like Location, Gender and Age. And then pair that with your intuition on who this audience may be.
                        </p>

                        <p class="lead mb-4">
                        For example, if you sell a product for kids and you have 30-50 year olds buying, those are probably parents. If you have 55+, those are probably grandparents. 
                        </p>

                        <p class="lead mb-4">
                        In addition, you can run ads with different interests, age ranges, locations, genders, etc. and then measure their conversion rates. That will give you the information on who your target market is.
                        </p>

                        <p class="lead mb-4">
                        Once you have your target market down, you can focus on bringing them to you. To do that, you can focus on a variety of marketing tactics that will drive them to you.
                        </p>

                        <h5>Paid Ads</h5>

                        <p class="lead mb-4">
                        Buy ads targeting these demographics across google, meta, tiktok, reddit, etc.

                        </p>

                        <h5>Go to where they already are:</h5>

                        <p class="lead mb-4">
                        Find influencers, websites, physical locations, etc. that already have these markets coming to them. Figure out a way to partner with them to get your product in front of the audience they’ve already created. From giving a free sample to paying for a review or an ad or giving them a share of the profit for any sales they make for you. Get creative and work with the people who already have your audiences attention.

                        </p>

                        <h5>Social Media</h5>

                        <p class="lead mb-4">
                        Make content on social media that will interest your target market. And then interact and participate online with the communities your target market is in.
                        </p>

                        <h5>SEO</h5>

                        <p class="lead mb-4">
                        Write blog posts and submit them to google with keywords designed for your target market to find them. For example if your target market is in a certain locations, write articles for every location they can be found in. “Jackets for Minnesotans” for example.
                        </p>

                        <h5>Work With Your Customers</h5>
                        <p class="lead mb-4">
                        Work with your current customers to generate and share content around your product with their friends. For example, “A competition for how long you can stay in -10 degree weather with your new coat. Share it online and get 10% off for every hour you spend outside!”


                        </p>
                        <p class="lead mb-4">
                        So, that’s how you can identify who your target market is and some ways to help drive them to your website.
                        </p>


                        

                <CTA />
            </div>
        );
    }
}
