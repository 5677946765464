import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';
import CTA from './cta.js';

export default class ProductDescriptionsToImproveConversions extends Component {

    render() {

        return (
            <div class='container'>

                    <h1>
                        Write Product Descriptions That Improve Your Conversion Rate
                    </h1>

                        <p class="lead mb-4">
                        Earlier, we talked about <a href='/ways_to_increase_conversion_rate/' target='_blank'>all the ways to increase your conversion rate.</a>
                        </p>

                        <p class="lead mb-4">
                        And now you are here to increase your conversion rate by writing a great product description.

                       </p>

                       <p class="lead mb-4">
                       There are a few ways to write a product description and a few things every product description needs. Let’s go over a few and look at a few examples together.
                       </p>

                       <h5><strong>What every product description needs.</strong></h5>

                       <p class="lead mb-4">
                        A description that:
                      </p>

                       <ul class="lead mb-4">
                            <li>Makes it clear how the product will benefit your target market (their dream outcome)</li>
                            <li>How It Achieves That Dream Outcome</li>
                            <li><a href='/reasons_to_buy_now_to_improve_your_conversion_rate' target='_blank'>A Reason To Buy Now</a></li>
                        </ul>

                       <h5><strong>Different Types of Product Descriptions</strong></h5>

                       <p class="lead mb-4">
                       There are a variety of ways to write a description depending on your own style.

                       </p>

                       <p class="lead mb-4">
                       <strong>Personal Story:</strong> You may write the story of why this product came into existence, especially if you can bring to life the problem you or a customer was having and how this solution solved it.
                       </p>

                       <img src='https://insights-public.s3.us-west-2.amazonaws.com/desc_1.png' style={{'marginBottom':'50px'}} />

                       <p class="lead mb-4">
                       <strong>Humorous Description:</strong> Some brands like to take themselves less seriously and show off a humorous side while describing their product. For example, take a look at Dr. Squatch’s Pine Tar Soap.
                       </p>

                       <img src='https://insights-public.s3.us-west-2.amazonaws.com/desc_2.png' style={{'marginBottom':'50px'}} />

                       <p class="lead mb-4">
                       <strong>Straight to the point:</strong> Other brands often just make a list of specs or features of the product. This can be especially useful for a product that requires very specific requirements. Here is a description for the Cloudgo shoe at Zappos. 
                       </p>

                         <img src='https://insights-public.s3.us-west-2.amazonaws.com/desc_3.png' style={{'marginBottom':'50px'}}/>
                  

                       <p class="lead mb-4">
                       <strong>Using superlatives or highly descriptive language:</strong> Casper beds uses language like, “The Feel Sleepers Love”, and “Dreamy Sleep” to describe their bed.
                       </p>

                       <img src='https://insights-public.s3.us-west-2.amazonaws.com/desc_4.png' style={{'marginBottom':'50px'}} />

                       <p class="lead mb-4">
                        Choose your style, write some product descriptions and test to see which works best for you!
                       </p>
                        

                <CTA />
            </div>
        );
    }
}
