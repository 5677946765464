import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class InvestigateTechnicalIssue extends Component {
    constructor(props) {
        super(props);

        this.state = {
            insight: { opportunity_size: 0 },
            data: {},
            loaded: false,
        };

        this.get_insight = this.get_insight.bind(this);
        this.load_insight = this.load_insight.bind(this);
    }

    componentDidMount() {
        this.get_insight();
    }

    get_insight() {
        let params = get_global_state()['params'];
        ajax_wrapper(
            'GET',
            `/api/get_insight/?type=investigate_technical_issue&deviceCategory=${params['deviceCategory']}&browser=${params['browser']}`,
            {},
            this.load_insight,
        );
    }

    load_insight(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    render() {
        let how_to_investigate = (
            <div>
                <h2>How To Investigate A Technical Issue on a Device</h2>
                <p>
                    Usually, there is a bad design or a broken page on the
                    device.
                </p>
                <p>
                    For seeing devices,{' '}
                    <a href="https://developer.chrome.com/docs/devtools/device-mode">
                        Chrome can simulate tablets and mobile phones
                    </a>{' '}
                    fairly well for design issues.
                </p>
                <p>
                    If you find a screen that has issues (and make sure you go
                    through the whole process of trying to buy something). Then
                    you will likely need a designer and a developer to help you
                    fix it. If you can do it on your own, great. Otherwise, we
                    are happy to help.
                </p>
            </div>
        );

        let params = get_global_state()['params'];
        if (params['browser'] != 'undefined') {
            how_to_investigate = (
                <div>
                    <h2>How To Investigate A Technical Issue For A Browser</h2>
                    <p>
                        Usually, there is a bad design or a broken page on the a
                        browser that causes issues.
                    </p>
                    <p>
                        For seeing browsers,{' '}
                        <a href="https://browser-update.org/browsers.html">
                            you can find all browsers linked here.
                        </a>{' '}
                        Download the one you need and try it out. Note that some
                        browsers will required specific operating systems and so
                        you may need to ask a friend or professional for help.
                    </p>
                    <p>
                        If you find a screen that has issues (and make sure you
                        go through the whole process of trying to buy
                        something). Then you will likely need a designer and a
                        developer to help you fix it. If you can do it on your
                        own, great. Otherwise, we are happy to help.
                    </p>
                </div>
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <Button href="/insights/" type="outline-success">
                        Back to Insights Report
                    </Button>
                    <div style={{ height: '20px' }}></div>
                    <div class="col-12 d-flex flex-column gap-2">
                        <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-4 rounded-3">
                            <svg class="bi" width="1em" height="1em"></svg>
                        </div>
                        <h4 class="fw-semibold mb-0 text-body-emphasis">
                            <Button type="dark">
                                Investigate A Technical Issue
                            </Button>
                        </h4>
                        <p class="text-body-secondary">
                            {this.state.insight.opportunity}
                        </p>
                        <p>
                            <b>Monthly Opportunity:</b> $
                            {this.state.insight.opportunity_size.toFixed(0)}
                        </p>
                    </div>

                    {how_to_investigate}
                    <Button
                        type="success"
                        href="https://calendly.com/jeremy-thiesen1/meetings"
                        target="_blank"
                    >
                        Schedule Time With Us To Help
                    </Button>
                </Container>
            </Loading>
        );
    }
}
