import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';
import CTA from './cta.js';

export default class ReviewsToImproveConversions extends Component {

    render() {

        return (
            <div class='container'>

                    <h1>
                        Increase Your Conversion Rate By Getting Your Target Market To Your Website
                    </h1>

                        <p class="lead mb-4">
                        Earlier, we talked about <a href='/ways_to_increase_conversion_rate/' target='_blank'>all the ways to increase your conversion rate.</a>
                        </p>

                        <p class="lead mb-4">
                        And now you are here to increase your conversion rate by getting great user reviews!

                       </p>

                       <p class="lead mb-4">
                       At first, I recommend getting user interviews in a more time consuming way and then after that you can automate the collection of reviews.

                       </p>

                       <h5><strong>User Interviews</strong></h5>

                       <p class="lead mb-4">
                       It’s simple, talk to your customers. Ask them why they bought your product, what they were hoping for and if your product delivered on that. If it didn’t, take notes and figure out how you can make their experience great now, plus figure out how to improve on it. If they loved what you gave them, ask them if they’ll write a review for you. Ask them to emphasize the thing that they were hoping for and how it specifically made it happen.
                       </p>

                       <p class="lead mb-4">
                       The reason you want to start this way is a couple of reasons. Firstly, you are focusing on just getting positive user reviews and privately taking notes and making negative user experiences better. Secondly, you can help your users craft a review that converts. For example, a review of, “was great!” isn’t going to help build your case as much as “I bought this jacket to keep me warm in the freezing Canadian winters. Not only did it arrive in just a couple of days, but I’ve been able to spend all day outside while feeling warm the whole time. Thank you _____. You’ve made my winter wonderful.”

                       </p>

                       <p>Here are some <a href='https://www.ecommerce-gold.com/best-shopify-review-apps/' target='_blank'>Shopify Review App Recommendations</a></p>

                       <h5><strong>Automated Reviews</strong></h5>
                       <p class="lead mb-4">
                       Once you have 10-25 reviews from your user interviews, you’ll be ready to automate the process so you can gather a lot more and do so without needing to jump in.

                       </p>

                       <p class="lead mb-4">
                       Once you’ve installed a review app from above, it’s easy for customers to review your product. From there, the key is reminding your customers to do it with an email after they purchased. 
                       </p>

                       <p class="lead mb-4">
                       <a href='https://judge.me/' target='_blank'>Judge.me</a>, <a href='https://www.yotpo.com/' target='_blank'>yotpo</a>, and <a href='https://stamped.io/' target='_blank'>Stamped</a> can all handle this for you automatically. 
                       </p>

                       <p class="lead mb-4">
                       With automated reviews, you’ll want to keep an eye out for any negative reviews so you can work with and rectify any issues and turn that negative review into a positive experience that can show off your customer service. 
                       </p>


                        

                <CTA />
            </div>
        );
    }
}
