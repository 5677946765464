import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';

export default class FreeConversionCall extends Component {
    constructor(props) {
        super(props);

        this.sign_up = this.sign_up.bind(this);
    }

    sign_up() {
        window.gtag('event', 'schedule_call');
        //console.log("Sign up event")

        window.location.href = 'https://calendly.com/jeremy-thiesen1/meetings'
    }

    render() {

        return (
            <div>
                <div
                    class="container col-xxl-8 px-4 py-5"
                    style={{ maxWidth: '1500px' }}
                >
                    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div class="col-10 col-sm-12 col-lg-6">
                            <iframe
                                src="https://insights-public.s3.us-west-2.amazonaws.com/reddit_conversions.mp4"
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowfullscreen
                                style={{
                                    width: '100%',
                                    height: '314px',
                                }}
                            ></iframe>
                        </div>
                        <div class="col-lg-6">
                            <h1 class="display-5 fw-bold lh-1 mb-3">
                                I want to help you increase your conversion rate for free! (limited to 10 people)
                            </h1>
                            <p class="lead">
                                Why? I'm building a product to help shopify stores increase their conversion rates and looking to test and develop it with you.
                            </p>
                            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                                <Button
                                    type="primary"
                                    onClick={this.sign_up}
                                    style={{
                                        fontSize: '25px',
                                        padding: '10px',
                                    }}
                                >
                                    Schedule A Call Now
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="px-4 py-5 my-5 text-center bg-dark text-light">
                    <h1 class="display-5 fw-bold text-white">
                        Why Conversion Rates?
                    </h1>
                    <div class="col-lg-6 mx-auto">
                        <p class="lead mb-4">
                            Increasing your conversion rate, even by a little, will change your business. You can scale faster, you will understand your customer better and you'll make more money immediately.
                        </p>
                    </div>
                </div>

                <section class="py-5 text-center container">
                    <div class="row py-lg-5">
                        <div class="col-lg-12 col-md-12 mx-auto">
                            <h1 class="fw-light">
                                You Are In Good Hands
                            </h1>
                            <p class="lead text-body-secondary">
                                Check out some of the growth myself and my marketing team have achieved by addressing conversion rates and ad campaigns.
                            </p>
                        </div>
                    </div>
                </section>

                <div class="album py-5 bg-body-tertiary">
                    <div class="container">
                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                            <div class="col">
                                <div class="card shadow-sm">
                                    <img
                                        src="https://justbeehoney.co.uk/cdn/shop/articles/104740553_l2_5000x.jpg?v=1672395671"
                                        class="bd-placeholder-img card-img-top"
                                        width="100%"
                                        height="225"
                                    ></img>

                                    <div class="card-body">
                                        <p class="card-text">
                                            In 3 months (July - Aug 2024), we took a beekeeping product company from 7k/mon - 32k/mon. Their business grew by 4x in 3 months!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card shadow-sm">
                                    <img
                                        class="bd-placeholder-img card-img-top"
                                        width="100%"
                                        height="225"
                                        src="https://static.vecteezy.com/system/resources/previews/022/874/375/non_2x/world-mental-health-day-illustration-concept-world-health-day-images-heartbeat-stethoscope-world-health-day-april-7-poster-banner-design-theme-2023-generate-ai-free-photo.jpg"
                                    ></img>
                                    <div class="card-body">
                                        <p class="card-text">
                                            In 4 months (May - Aug 2024), we took a sports nutrition company from 3k/mon to 12k/mon. Now they are off to the races.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card shadow-sm">
                                    <img
                                        class="bd-placeholder-img card-img-top"
                                        width="100%"
                                        height="225"
                                        src="https://www.acspack.co.za/wp-content/uploads/2021/05/ulysse-pointcheval-j6LLsAehUo-unsplash.jpg"
                                    />
                                    <div class="card-body">
                                        <p class="card-text">
                                            We took a cologne company from 19k/mon to 40k/mon from from July-Aug 2024. Doubling them in just 3 months.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div style={{ height: '40px' }}></div>
                <div class="px-4 my-5 text-center">
                    <h1 class="display-5 fw-bold text-body-emphasis">
                        What to do next to increase your conversion rate?
                    </h1>
                    <div class="col-lg-6 mx-auto">
                    </div>
                </div>
                <div class="d-flex flex-column flex-md-row p-4 gap-4 py-md-5 align-items-center justify-content-center">
                    <div class="list-group">
                    <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                checked="true"
                                style={{ fontSize: '1.375em' }}
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>
                                    You Will Schedule A Call
                                </strong>

                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                checked="true"
                                style={{ fontSize: '1.375em' }}
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>
                                    We'll Go Over Your Site And Goals
                                </strong>

                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked="true"
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>
                                    I'll show you the process of increasing conversions based on your site.
                                </strong>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked="true"
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>We'll Set Up A Plan</strong>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked="true"
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>I'll Optimize Your Conversion Rate</strong>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3 bg-body-tertiary">
                        <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked="true"
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>
                                    We'll Celebrate Your Successful Company
                                </strong>
                            </span>
                        </label>
                    </div>
                </div>

                <div class="col-lg-6 mx-auto">
                    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <Button
                                type="primary"
                                onClick={this.sign_up}
                                style={{ fontSize: '30px' }}
                            >
                                Schedule A Quick 30 Minute Call
                            </Button>
                    </div>
                </div>
            </div>
        );
    }
}
