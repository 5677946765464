import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';
import CTA from './cta.js';

export default class VideoToImproveConversions extends Component {

    render() {

        return (
            <div class='container'>

                    <h1>
                    Create Videos That Improve Your Conversion Rate
                    </h1>

                        <p class="lead mb-4">
                        Earlier, we talked about <a href='/ways_to_increase_conversion_rate/' target='_blank'>all the ways to increase your conversion rate.</a>
                        </p>

                        <p class="lead mb-4">
                        And now you are here to increase your conversion rate by getting great product videos.

                       </p>

                       <p class="lead mb-4">
                       Videos are very similar to photos in that they are another way to give proof to your potential customers that your product will give them what they want. Videos can showcase that the product delivers on exactly what you say it can. However, video also gives you the opportunity to use product descriptions and to talk about what you are showing. Refer back to product descriptions on how to think about making the audio for your video.

                       </p>
                       <p class="lead mb-4">
                       **One thing to note is that people will buy a product if they literally see themselves using it. So, if your target market is men at a beach, show men on the beach, if it's for fit men vs. overweight men, show a variety of people in your market using your product for its intended purpose.

                       </p>

                       <h5><strong>Staged Videos</strong></h5>
                       <p class="lead mb-4">
                       Staged videos have been used over and over again to drive massive audiences. Think back to the famous Dollar Shave Club example.

                       </p>

                       <iframe width="560" height="315" src="https://www.youtube.com/embed/ZUG9qYTJMsI?si=iNv2jd6N997FaNyZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                       <p class="lead mb-4">
                       Or something more concrete to show off the product like the Solo Stoves video below:
                       </p>

                       <iframe width="560" height="315" src="https://www.youtube.com/embed/83tuiTSBK3U?si=njaRVhEbq-46d-EO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                       
                       <h5><strong>Customer Videos</strong></h5>
                       <p class="lead mb-4">
                       The other way you can generate videos is through customers. Using the same process to <a href='/reviews_to_improve_your_conversion_rate' target='_blank'>get reviews</a>, you can get customers to post videos of your product in use. If you go this route, ask your customers to post photos on social media of them using it and tag you. You can drive traffic through their photos and showcase your product not just on your store, but on social media as well.
                       </p>
                       <p class="lead mb-4">
                       In addition, check out <a href='https://www.gotolstoy.com/shoppable-video'>Tolstoy</a>  for easy technology integrations to showcase your videos.
                       </p>
   


                        

                <CTA />
            </div>
        );
    }
}
