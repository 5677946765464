import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class RunAd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            insight: { opportunity_size: 0 },
            data: [],
            loaded: false,
        };

        this.get_insight = this.get_insight.bind(this);
        this.load_insight = this.load_insight.bind(this);
    }

    componentDidMount() {
        this.get_insight();
    }

    get_insight() {
        let params = get_global_state()['params'];
        let url = '';
        if (params['source']) {
            url = `/api/get_insight/?type=run_ad&item_id=${params['item_id']}&source=${params['source']}&medium=${params['medium']}`;
        } else {
            url = `/api/get_insight/?type=run_ad&item_id=${params['item_id']}&age=${params['userAgeBracket']}&gender=${params['userGender']}&region=${params['region']}`;
        }

        ajax_wrapper('GET', url, {}, this.load_insight);
    }

    load_insight(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    render() {
        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <Button href="/insights/" type="outline-success">
                        Back to Insights Report
                    </Button>
                    <div style={{ height: '20px' }}></div>
                    <div class="col-12 d-flex flex-column gap-2">
                        <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                            <svg class="bi" width="1em" height="1em"></svg>
                        </div>
                        <h4 class="fw-semibold mb-0 text-body-emphasis">
                            <Button type="dark">Run An Ad</Button>
                        </h4>
                        <p class="text-body-secondary">
                            {this.state.insight.opportunity}
                        </p>
                        <p>
                            <b>Monthly Opportunity:</b> $
                            {this.state.insight.opportunity_size.toFixed(0)}
                        </p>
                    </div>

                    <h2>How To Run An Ad</h2>
                    <p>
                        Ads is a massive topic, so we are just going to outline
                        a single principle below and link you to some useful
                        resources.
                    </p>
                    <p>
                        <b>ROAS (Return on Ad Spend):</b> I'm starting here
                        because this is the metric to keep track of, its how
                        much you make vs. how much you are spending. For
                        example, let's say I spend $100 and get $200 in
                        purchases, that is a ROAS of 2. Average ROAS is around
                        2.87. So, keep that in mind when running ads of how you
                        are performing.
                    </p>
                    <p>
                        More important than average ROAS is your break-even
                        ROAS. That is the number you need to hit to be earning
                        more than you are spending. You can calculate this by
                        using your profit margin. For example, if you have a
                        profit margin of 50%, your breakeven ROAS would be 1/50%
                        = 2. And if you had an average ROAS of 2.87, you'd be
                        making money!
                    </p>
                    <p>
                        Now that you know how well you need to perform. You can
                        take that anywhere. For demographics, you can run ads
                        via ad networks with Meta, Google, Twitter, TikTok, etc.
                        However, you can also find sites related to your product
                        that may follow that demographic. (Ex. Getting a review
                        from{' '}
                        <a href="https://thehappyhousewife.com/real-life/clothing-subscription-review/">
                            The Happy Housewife
                        </a>{' '}
                        would likely be a good place to attract housewives.) And
                        you can go through the same thought process with
                        influencers.
                    </p>
                    <p>
                        It doesn't matter where or how you advertise as long as
                        you have a better ROAS than your break-even.
                    </p>

                    <p>And here are some guides to get you started:</p>

                    <ul>
                        <li>
                            <a
                                href="https://blog.hootsuite.com/google-ads/"
                                target="_blank"
                            >
                                Getting Started with Google Ads
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://blog.hubspot.com/marketing/facebook-paid-ad-checklist"
                                target="_blank"
                            >
                                Getting Started with Facebook/Meta Ads
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://impact.com/partnerships/ultimate-guide-to-influencer-marketing/"
                                target="_blank"
                            >
                                Getting Started with Influencer Marketing
                            </a>
                        </li>
                    </ul>

                    <Button
                        type="success"
                        href="https://calendly.com/jeremy-thiesen1/meetings"
                        target="_blank"
                    >
                        Schedule Time With Us To Help
                    </Button>
                </Container>
            </Loading>
        );
    }
}
