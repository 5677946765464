import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';
import CTA from './cta.js';

export default class KnowYourTargetMarketsDreamOutcome extends Component {

    render() {

        return (
            <div class='container'>

                    <h1>
                        Increase Your Conversion Rate By Knowing Your Target's Dream Outcome
                    </h1>

                        <p class="lead mb-4">
                        Earlier, we talked about <a href='/ways_to_increase_conversion_rate/' target='_blank'>all the ways to increase your conversion rate.</a>
                        </p>

                        <p class="lead mb-4">
                        And now you are here to increase your conversion rate by better understanding your audiences dream outcome, how do you do that?


                       </p>

                       <p class="lead mb-4">
                       I think that there are two main ways to do this, the qualitative way and the quantitative way and you’ll want to use both to figure it out.
                       </p>

                        <h5><strong>The qualitative way: User Interviews.</strong></h5>
                       <p class="lead mb-4">
                       It’s simple, talk to your customers. Ask them why they bought your product, what were they hoping for and what compelled them to buy your product instead of something else. Note: this is also a great time to <a href='/reviews_to_improve_your_conversion_rate' target='_blank'>get/ask for a review.</a>
                       </p>
                       <p class="lead mb-4">
                       Once you talk to a bunch of them, you’ll probably start piecing together a trend of what attracts customers to your product specifically, think not just about the features, but also the specific words used or even the feeling they got. That can inform you about not just why they bought your product, but what they like about your brand.
                       </p>

                       <h5>How do you get people to talk to you?</h5>
                       <p class="lead mb-4">
                       Depending on what you sell, it may be a little different, but you can just reach out to your customers via email and schedule some time. You may need to be available late or on weekends if they work during the day. Make it short and think about offering a discount on their next purchase if they need a little more convincing. 
                       </p>
                       <p class="lead mb-4">
                       For example:
                       </p>

                        <div class='container' style={{border:'1px solid black', margin:'30px'}}>
                            <p><strong>Subject Line:</strong> Get 10% off your next purchase at _____ for 15 minutes of your time.</p>
                            <p><strong>Body:</strong></p>
                            <p>Hi, ____</p>
                            <p>I’m reaching out from _____. And I saw that you bought _____. It would be a huge help to me if you could talk to me about what helped you make the decision to buy _____. And I’d love to give you a little discount if you can spend 15 minutes with me, I’ll even make it fun and tell you my favorite joke!</p>
                            <p><a>Scheduling Link</a></p>
                            <p>Talk Soon,</p>
                            <p>Jeremy</p>
                        </div>


                        <h5><strong>The quantitative way: Split Testing.</strong></h5>

                       <p class="lead mb-4">
                       The other way to figure out why your customers are buying is to test different language, images, etc. to tell a different story and see what converts better.
                       </p>

                       <p class="lead mb-4">
                       For example, does your target market buy your coat because its fashionable or because it keeps them warm in any temperature?
                       </p>

                       <p class="lead mb-4">
                       Try writing a description that focuses on how warm it is, with pictures and videos of it being used outside in extreme condition.
                       </p>

                       <p class="lead mb-4">
                       Try another way of describing how you’ve never looked better in the winter, pictures of models in your jacket and people complimenting how good it looks. 
                       </p>

                       <p class="lead mb-4">
                       Split test them and see which of the two gets better conversions! 
                       </p>

                       <p class="lead mb-4">
                        <a href='https://www.charleagency.com/articles/best-AB-testing-tools-shopify/' target='_blank'>
Here is a list of Shopify Apps to help you AB Test.
</a>
                       </p>

                       <p class="lead mb-4">
                       And you’ll never want to stop split testing. Try big things and small things. Use the words that your customers used in your interviews and slowly but surely identify all the little ways that showcase the outcome your customers want.
                       </p>


                        

                <CTA />
            </div>
        );
    }
}
