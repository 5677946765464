import React, { Component } from 'react';

import { Image, Button } from 'library';
import { UserValidator } from 'functions';
import { isMobile } from 'react-device-detect';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    render() {
        let checker = new UserValidator();
        let is_logged_in = checker.logged_id();
        let is_staff = checker.is_staff();

        let navbar_collapse = 'collapse navbar-collapse';
        let navbar_toggle = (
            <Button
                class="navbar-toggler"
                type="button"
                onClick={() => this.setState({ show: !this.state.show })}
            >
                <span class="navbar-toggler-icon"></span>
            </Button>
        );

        let items = [];

        if (!is_logged_in) {
            items.push(
                <Button
                    type="outline-primary"
                    href="/user/google_redirect/"
                    style={{ marginRight: '5px' }}
                >
                    Login
                </Button>,
            );
            items.push(
                <Button type="primary" href="/user/google_redirect/">
                    Sign-up
                </Button>,
            );
        }

        if (is_staff) {
            // Add staff routes to header
            items.push(
                <Button
                    type="outline-danger"
                    href="/admin/ghost_as_user/"
                    style={{ marginRight: '5px' }}
                >
                    Ghost As User
                </Button>,
            );
        }

        if (is_logged_in) {
            // Add authed routes to header
            items.push(
                <Button
                    type="outline-dark"
                    href="/accounts_and_properties/?change_property=True"
                    style={{ marginRight: '5px' }}
                >
                    Change Property
                </Button>,
            );
            items.push(
                <Button type="outline-danger" href="/logout/">
                    Logout
                </Button>,
            );
        }

        return (
            <div className="container">
                <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
                    <a
                        href="/"
                        class="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
                    >
                        <img
                            src="https://media.istockphoto.com/id/1225609127/vector/growing-business-concept.jpg?s=612x612&w=0&k=20&c=RWDV5WgdK55I-9KB9xWBWs770pWE3qCGK34ZCvB1uG8="
                            class="bi me-2"
                            width="40"
                            height="32"
                            role="img"
                            aria-label="Bootstrap"
                        ></img>
                        Insights Marketing
                    </a>

                    <div class="col-md-3 text-end">{items}</div>
                </header>
            </div>
        );
    }
}

export default Header;
