import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';
import CTA from './cta.js';

export default class ImagesToImproveConversions extends Component {

    render() {

        return (
            <div class='container'>

                    <h1>
                    Get Images That Improve Your Conversion Rate
                    </h1>

                        <p class="lead mb-4">
                        Earlier, we talked about <a href='/ways_to_increase_conversion_rate/' target='_blank'>all the ways to increase your conversion rate.</a>
                        </p>

                        <p class="lead mb-4">
                        And now you are here to increase your conversion rate by getting great product photography.
                       </p>

                       <p class="lead mb-4">
                       Photos are just another way to give proof to your potential customers that your product will give them what they want. Photos can showcase the product itself so it is easy to understand what you are selling, they can show off features of the product to prove it does what you say it does or they can be “lifestyle” photos that show that people they know or trust use it.
                       </p>

                       <p class="lead mb-4">
                       One thing to note is that people will buy a product if they literally see themselves using it. So, if your target market is men at a beach, show men on the beach, if it's for fit men vs. overweight men, show a variety of people in your market using your product for its intended purpose.
                       </p>

                        <h5><strong>Staged Photography</strong></h5>

                       <p class="lead mb-4">
                       Staged photography is when you either take the photos yourself or hire a photographer to do it. You are showcasing the product and using the photo to sell the features and benefits of your product.
                       </p>

                       <p class="lead mb-4">
                       Below are a few examples:
                       </p>

                       

                       <img src='https://insights-public.s3.us-west-2.amazonaws.com/pics_1.png' />
                       <p class="lead mb-4">
                       Swimwear for overweight men
                       </p>

                       

                       <img src='https://insights-public.s3.us-west-2.amazonaws.com/pics_2.png' />
                       <p class="lead mb-4">
                       Jacket for freezing weather
                       </p>

                       

                       <img src='https://insights-public.s3.us-west-2.amazonaws.com/pics_3.png' />
                       <p class="lead mb-4">
                       A sustainable water bottle
                       </p>

                       <h5><strong>Customer Photography</strong></h5>

                       <p class="lead mb-4">
                       The other way you can generate photography is through customers. Using the same process to <a href='/reviews_to_improve_your_conversion_rate' target='_blank'>get reviews,</a> you can get customers to post photos of your product in use. If you go this route, ask your customers to post photos on social media of them using it and tag you. You can drive traffic through their photos and showcase your product not just on your store, but on social media as well.
                       </p>



                        

                <CTA />
            </div>
        );
    }
}
