import {
    AdminRoutes,
    AuthedRoutes,
    AnonymousRoutes,
    Login,
    Logout,
    AdminDashboard,
    ObjectList,
    ManageObject,
    LoadLastURL,
    CurrentNotOffered,
    PrivacyPolicy,
    SignUp,
    Home,
    Insights,
    GoogleLogin,
    AccountsAndProperties,
    MakeBetterOffer,
    RunAd,
    InvestigateConversionIssue,
    InvestigateTechnicalIssue,
    Ghost,
    ConversionHome,
    FreeConversionCall,
    WaysToIncreaseConversion,
    GetYourTargetMarketOnYourSite,
    KnowYourTargetMarketsDreamOutcome,
    ImagesToImproveConversions,
    VideoToImproveConversions,
    UseAGuaranteeToImproveConversions,
    ReviewsToImproveConversions,
    ReasonsToBuyNow,
    ProductDescriptionsToImproveConversions,
} from 'pages';

const routes = [
    {
        path: 'logout',
        element: <Logout />,
    },
    {
        path: 'login',
        element: <Home />,
    },

    {
        path: 'load_last_url',
        element: <LoadLastURL />,
    },
    {
        path: '',
        element: <AnonymousRoutes />,
        children: [
            {
                index: true,
                element: <FreeConversionCall />,
            },
            {
                path: 'conversion_rate',
                element: <FreeConversionCall />,
            },
            {
                path: 'privacy_policy',
                element: <PrivacyPolicy />,
            },
            {
                path: 'google_login',
                element: <GoogleLogin />,
            },
            {
                path: 'ways_to_increase_conversion_rate',
                element: <WaysToIncreaseConversion />,
            },
            {
                path: 'get_your_target_market_on_your_site',
                element: <GetYourTargetMarketOnYourSite />,
            },
            {
                path: 'know_your_target_markets_dream_outcome',
                element: <KnowYourTargetMarketsDreamOutcome />,
            },
            {
                path: 'product_descriptions_to_improve_your_conversion_rate',
                element: <ProductDescriptionsToImproveConversions />,
            },
            {
                path: 'images_to_improve_your_conversion_rate',
                element: <ImagesToImproveConversions />,
            },
            {
                path: 'videos_to_improve_your_conversion_rate',
                element: <VideoToImproveConversions />,
            },
            {
                path: 'use_a_guarantee_to_improve_your_conversion_rate',
                element: <UseAGuaranteeToImproveConversions />,
            },
            {
                path: 'reviews_to_improve_your_conversion_rate',
                element: <ReviewsToImproveConversions />,
            },
            {
                path: 'reasons_to_buy_now_to_improve_your_conversion_rate',
                element: <ReasonsToBuyNow />,
            },
        ],
    },
    {
        path: '',
        element: <AuthedRoutes />,
        children: [
            {
                path: 'insights',
                element: <Insights />,
            },
            {
                path: 'accounts_and_properties',
                element: <AccountsAndProperties />,
            },
            {
                path: 'make_better_offer',
                element: <MakeBetterOffer />,
            },
            {
                path: 'run_ad',
                element: <RunAd />,
            },
            {
                path: 'investigate_conversion_issue',
                element: <InvestigateConversionIssue />,
            },
            {
                path: 'investigate_technical_issue',
                element: <InvestigateTechnicalIssue />,
            },
        ],
    },
    {
        path: 'admin',
        element: <AdminRoutes />,
        children: [
            {
                path: 'ghost_as_user',
                element: <Ghost />,
            },
        ],
    },
];

export default routes;
