import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.sign_up = this.sign_up.bind(this);
    }

    sign_up() {
        window.gtag('event', 'sign_up');
        //console.log("Sign up event")

        window.location.href = '/user/google_redirect/'
    }

    render() {
        if (window.secret_react_state.user) {
            window.location.href = '/accounts_and_properties/';
        }

        return (
            <div>
                <div
                    class="container col-xxl-8 px-4 py-5"
                    style={{ maxWidth: '1500px' }}
                >
                    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div class="col-10 col-sm-12 col-lg-6">
                            <iframe
                                src="https://insights-public.s3.us-west-2.amazonaws.com/PXL_20241014_164057527.TS.mp4"
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowfullscreen
                                style={{
                                    width: '100%',
                                    height: '314px',
                                }}
                            ></iframe>
                        </div>
                        <div class="col-lg-6">
                            <h1 class="display-5 fw-bold lh-1 mb-3">
                                We help shopify stores improve their conversion rate, guaranteed.
                            </h1>
                            <p class="lead">
                                We do this by using analytics to show what to focus on and how much its worth, frameworks that drive conversions and a team of experts.
                            </p>
                            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                                <Button
                                    type="primary"
                                    onClick={this.sign_up}
                                    style={{
                                        fontSize: '25px',
                                        padding: '10px',
                                    }}
                                >
                                    Get Started For Free
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="px-4 py-5 my-5 text-center bg-dark text-light">
                    <h1 class="display-5 fw-bold text-white">
                        Profitable Next Steps Made Easy.
                    </h1>
                    <div class="col-lg-6 mx-auto">
                        <p class="lead mb-4">
                            We automatically identify the best ways to improve
                            your site, paired with the revenue it could generate
                            you and expert advice on how to do it.
                        </p>
                    </div>
                </div>

                <div class="container px-4 py-5">
                    <div class="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
                        <div class="col d-flex flex-column align-items-start gap-2">
                            <h1 class="fw-bold text-body-emphasis">
                                Our Algorithm Makes It Easy To Pinpoint Where You Could Improve
                            </h1>
                            <p class="lead">
                                Years ago, our Founder, Jeremy, had wrapped up a
                                previous startup and wanted to help some other
                                entrepreneurs by helping them look through their
                                analytics. Unfortunately, analytics platforms
                                are data heavy and insight poor. It would take
                                hours of frustration to get the right data to
                                know what to do. So, we committed to building
                                software to make it easy to find exactly what to
                                do next, how to do it and what it is worth.
                            </p>
                        </div>

                        <div class="col">
                        <h3>Sample Report</h3>
                            <div class="row g-4">
                                <div class="col-12 d-flex flex-column gap-2">
                                    <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                                        <svg
                                            class="bi"
                                            width="1em"
                                            height="1em"
                                        ></svg>
                                    </div>
                                    <h4 class="fw-semibold mb-0 text-body-emphasis">
                                        <button
                                            class="btn btn-dark "
                                            type="button"
                                        >
                                            Make A Better Offer
                                        </button>
                                    </h4>
                                    <p class="text-body-secondary">
                                        3123 people per month are visiting
                                        viewing the Blue Below Freezing Jacket,
                                        but only 24 of them added it to their
                                        cart. That's only 0.77% compared to a
                                        site average of 2.12%. Let's try making
                                        a more compelling offer.
                                    </p>
                                    <p>
                                        <b>Monthly Opportunity:</b> $9497
                                    </p>
                                </div>

                                <div class="col-12 d-flex flex-column gap-2">
                                    <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-4 rounded-3">
                                        <svg
                                            class="bi"
                                            width="1em"
                                            height="1em"
                                        ></svg>
                                    </div>
                                    <h4 class="fw-semibold mb-0 text-body-emphasis">
                                        <button
                                            class="btn btn-dark "
                                            type="button"
                                        >
                                            Fix A Technical Issue
                                        </button>
                                    </h4>
                                    <p class="text-body-secondary">
                                        214 people are visiting your site using
                                        the Edge Browser. However, 0% of them
                                        are converting compared to your site
                                        average of 2.12%. Let's investigate what
                                        is going wrong.
                                    </p>
                                    <p>
                                        <b>Monthly Opportunity:</b> $1020
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="container col-xxl-8 px-4 py-5"
                    style={{ maxWidth: '1500px' }}
                >
                    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div class="col-lg-6">
                            <h1
                                class="display-5 fw-bold lh-1 mb-3"
                                style={{ fontSize: '40px' }}
                            >
                                Every Insight is paired with Expert Guidance To
                                Implement It.
                            </h1>
                            <p class="lead" style={{ fontSize: '22px' }}>
                                In the modern world, there are experts in every
                                niche. And so we've paired every insight with
                                the right expert frameworks and advice so you
                                can understand the right way to implement. And
                                if you want more help, with one button click we
                                will set you up with an expert marketer to do
                                the implementation for you.
                            </p>
                        </div>
                        <div class="col-10 col-sm-12 col-lg-6">
                            <h3>How To Make A Better Offer</h3>
                            <iframe
                                src="https://www.loom.com/embed/6dcb187daaa647b1ae800eb79f526c70?sid=4015c5be-f994-459c-af6f-c92eb5f3c1b0"
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowfullscreen
                                style={{
                                    width: '100%',
                                    height: '314px',
                                }}
                            ></iframe>
                        </div>
                    </div>
                </div>

                <div class="px-4 py-5 my-5 text-center bg-dark text-light">
                    <h1 class="display-5 fw-bold text-white">
                        The Above Report Is Yours For The Taking, For Free, For
                        now.
                    </h1>
                    <div class="col-lg-6 mx-auto">
                        <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                        <Button
                                type="primary"
                                onClick={this.sign_up}
                                style={{ fontSize: '30px' }}
                            >
                                Get It Now!
                            </Button>
                        </div>
                        <p>
                            Or beat yourself up later for not doing this now and
                            missing out on growing your business sooner.
                        </p>
                    </div>
                </div>

                <section class="py-5 text-center container">
                    <div class="row py-lg-5">
                        <div class="col-lg-12 col-md-12 mx-auto">
                            <h1 class="fw-light">
                                A World Class Marketing Team
                            </h1>
                            <p class="lead text-body-secondary">
                                Don't worry, we aren't going to leave you high
                                and dry once you have access to all this awesome
                                new information. Our marketing team is there to
                                help you every step of the way no matter your industry or current size.
                            </p>
                        </div>
                    </div>
                </section>

                <div class="album py-5 bg-body-tertiary">
                    <div class="container">
                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                            <div class="col">
                                <div class="card shadow-sm">
                                    <img
                                        src="https://justbeehoney.co.uk/cdn/shop/articles/104740553_l2_5000x.jpg?v=1672395671"
                                        class="bd-placeholder-img card-img-top"
                                        width="100%"
                                        height="225"
                                    ></img>

                                    <div class="card-body">
                                        <p class="card-text">
                                            In 3 months (July - Aug 2024), they took a beekeeping product company from 7k/mon - 32k/mon. Their business grew by 4x in 3 months!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card shadow-sm">
                                    <img
                                        class="bd-placeholder-img card-img-top"
                                        width="100%"
                                        height="225"
                                        src="https://static.vecteezy.com/system/resources/previews/022/874/375/non_2x/world-mental-health-day-illustration-concept-world-health-day-images-heartbeat-stethoscope-world-health-day-april-7-poster-banner-design-theme-2023-generate-ai-free-photo.jpg"
                                    ></img>
                                    <div class="card-body">
                                        <p class="card-text">
                                            In 4 months (May - Aug 2024), they took a sports nutrition company from 3k/mon to 12k/mon. Now they are off to the races.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card shadow-sm">
                                    <img
                                        class="bd-placeholder-img card-img-top"
                                        width="100%"
                                        height="225"
                                        src="https://www.acspack.co.za/wp-content/uploads/2021/05/ulysse-pointcheval-j6LLsAehUo-unsplash.jpg"
                                    />
                                    <div class="card-body">
                                        <p class="card-text">
                                            They took a cologne company from 19k/mon to 40k/mon from from July-Aug 2024. Doubling them in just 3 months.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container col-xl-10 col-xxl-8 px-4 py-5">
                    <div class="row align-items-center g-lg-5 py-5">
                        <div class="col-lg-7 text-center text-lg-start">
                            <h1 class="display-4 fw-bold lh-1 text-body-emphasis mb-3">
                                I love this! But, I don't have the money for
                                marketing.
                            </h1>
                            <p class="col-lg-10 fs-4">
                                Don't worry, we've been there too. Let us ask
                                you:
                            </p>
                            <ul>
                                <li class="fs-4">
                                    Have you poured your heart and soul into
                                    your product?
                                </li>
                                <li class="fs-4">
                                    Would the world be a better/happier/joyful
                                    place if more people had your products?
                                </li>
                            </ul>
                            <p class="col-lg-10 fs-4">If so, we can help!</p>
                        </div>
                        <div class="col-md-10 mx-auto col-lg-5">
                            <p class="fs-4">
                                If you need our expertise, we will gladly help you out and put to work all the best practices in conversion rate optimization. And you only pay when your conversion rate significantly improves.
                            </p>
                            <Button
                                type="primary"
                                onClick={this.sign_up}
                                style={{ fontSize: '30px' }}
                            >
                                Sign Up And Schedule Time With Us!
                            </Button>
                        </div>
                    </div>
                </div>

                <div style={{ height: '40px' }}></div>
                <div class="px-4 my-5 text-center">
                    <h1 class="display-5 fw-bold text-body-emphasis">
                        One more time, what are we offering?
                    </h1>
                    <div class="col-lg-6 mx-auto">
                        <p class="lead mb-4">
                            Hopefully, you've understood everything as a totally
                            risk free offer, and it is.
                        </p>
                    </div>
                </div>
                <div class="d-flex flex-column flex-md-row p-4 gap-4 py-md-5 align-items-center justify-content-center">
                    <div class="list-group">
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                checked="true"
                                style={{ fontSize: '1.375em' }}
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>
                                    Report Showing You The Best Strategies To
                                    Grow
                                </strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Free! ($400 value)
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked="true"
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>
                                    Expert Advice On How To Implement The Above
                                    Strategies
                                </strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Free! (Don't Spend The Time And Effort
                                    Finding It Yourself)
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked="true"
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>Marketing Consultation</strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Free ($100 Value)
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked="true"
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>Conversion Rate Optimization</strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Free Upfront, Pay When Your Sales Increase
                                    ($10,000+ Value, you'll be needing more inventory!)
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3 bg-body-tertiary">
                            <input
                                class="form-check-input form-check-input-placeholder bg-body-tertiary flex-shrink-0 pe-none"
                                disabled=""
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked="true"
                            />
                            <span class="pt-1 form-checked-content">
                                <span contenteditable="true" class="w-100">
                                    A Successful Company
                                </span>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Priceless...
                                </small>
                            </span>
                        </label>
                    </div>
                </div>

                <div class="col-lg-6 mx-auto">
                    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <Button
                                type="primary"
                                onClick={this.sign_up}
                                style={{ fontSize: '30px' }}
                            >
                                Get Started Now
                            </Button>
                    </div>
                </div>
            </div>
        );
    }
}
