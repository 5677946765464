import React, { Component } from 'react';
import { UserValidator } from 'functions';
import { isMobile } from 'react-device-detect';

class Footer extends Component {
    render() {
        //let checker = new UserValidator();
        //let is_logged_in = checker.logged_id();
        //let is_staff = checker.is_staff();
        //let no_footer = false;

        let footer = (
            <div className="container">
                <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <p class="col-md-4 mb-0 text-body-secondary">
                        © 2024 Insights Marketing, Inc
                    </p>

                    <a
                        href="/"
                        class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
                    >
                        <svg class="bi me-2" width="40" height="32"></svg>
                    </a>

                    <ul class="nav col-md-4 justify-content-end">
                        <li class="nav-item">
                            <a
                                href="/privacy_policy"
                                class="nav-link px-2 text-body-secondary"
                            >
                                Privacy Policy
                            </a>
                        </li>
                    </ul>
                </footer>
            </div>
        );

        return footer;
    }
}

export default Footer;
