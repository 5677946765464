import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

class DataTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show_all: false,
        };

        this.toggle_show_all = this.toggle_show_all.bind(this);
    }

    toggle_show_all() {
        this.setState({ show_all: !this.state.show_all });
    }

    render() {
        let demo_rows = [];
        for (let demo of this.props.data) {
            demo_rows.push(
                <tr>
                    <td>{demo.demo}</td>
                    <td>{demo.itemsViewed}</td>
                    <td>{(demo.add_to_cart_rate * 100).toFixed(2)}%</td>
                    <td>${demo.opportunity_size.toFixed(0)}</td>
                </tr>,
            );

            if (demo_rows.length == 5 && !this.state.show_all) {
                break;
            }
        }
        if (demo_rows.length > 0) {
            let show_all = 'Show All';
            if (this.state.show_all) {
                show_all = 'Show Less';
            }
            return (
                <div class="col-6">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{this.props.demo_name}</th>
                                <th>Page Views (Last 30 days)</th>
                                <th>% Add To Cart</th>
                                <th>Opportunity Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {demo_rows}

                            <tr>
                                <td>
                                    <a
                                        onClick={this.toggle_show_all}
                                        style={{
                                            color: 'blue',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {show_all}
                                    </a>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default class MakeBetterOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            insight: { opportunity_size: 0 },
            supporting_data: {},
            loaded: false,
        };

        this.get_insight = this.get_insight.bind(this);
        this.load_insight = this.load_insight.bind(this);
    }

    componentDidMount() {
        this.get_insight();
    }

    get_insight() {
        let params = get_global_state()['params'];
        let url = '';
        if (params['source']) {
            url = `/api/get_insight/?type=make_better_offer&item_id=${params['item_id']}&source=${params['source']}&medium=${params['medium']}`;
        } else {
            url = `/api/get_insight/?type=make_better_offer&item_id=${params['item_id']}&age=${params['userAgeBracket']}&gender=${params['userGender']}&region=${params['region']}`;
        }

        ajax_wrapper('GET', url, {}, this.load_insight);
    }

    load_insight(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    render() {
        let support_tables = [];
        if (this.state.supporting_data.gender_list) {
            support_tables.push(
                <DataTable
                    data={this.state.supporting_data.gender_list}
                    demo_name="Gender"
                />,
            );
        }

        if (this.state.supporting_data.region_list) {
            support_tables.push(
                <DataTable
                    data={this.state.supporting_data.region_list}
                    demo_name="Region"
                />,
            );
        }

        if (this.state.supporting_data.age_list) {
            support_tables.push(
                <DataTable
                    data={this.state.supporting_data.age_list}
                    demo_name="Age Bracket"
                />,
            );
        }

        if (this.state.supporting_data.channel_list) {
            support_tables.push(
                <DataTable
                    data={this.state.supporting_data.channel_list}
                    demo_name="Traffic Source"
                />,
            );
        }

        let support_description = null;
        if (support_tables.length > 0) {
            support_description = (
                <h4>
                    Use the below data to understand who is coming and not
                    converting and how to make a better offer for them.
                </h4>
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <div class="col-12 d-flex flex-column gap-2">
                        <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                            <svg class="bi" width="1em" height="1em"></svg>
                        </div>
                        <h4 class="fw-semibold mb-0 text-body-emphasis">
                            <Button type="dark">Make A Better Offer</Button>
                        </h4>
                        <p class="text-body-secondary">
                            {this.state.insight.opportunity}
                        </p>
                        <p>
                            <b>Monthly Opportunity:</b> $
                            {this.state.insight.opportunity_size.toFixed(0)}
                        </p>
                    </div>

                    <div class="col-12">{support_tables}</div>

                    <h2>
                        How To Make A Better Offer And Increase Your Conversion
                        Rate
                    </h2>
                    <iframe
                        src="https://www.loom.com/embed/6dcb187daaa647b1ae800eb79f526c70?sid=4015c5be-f994-459c-af6f-c92eb5f3c1b0"
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        style={{
                            width: '600px',
                            height: '314px',
                        }}
                    ></iframe>
                    <p>The keys to making a great offer are highlighted below. We'll be using an examples for a low temperature jacket, fashionable clothing, a weight loss product and a product to help harvest honey from a bee-hive.</p>

                    <div class="d-flex flex-column flex-md-row p-4 gap-4 py-md-5 align-items-center justify-content-center">
                    <div class="list-group">
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                checked=""
                                style={{ fontSize: '1.375em' }}
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>
                                    Your Product Helps Them Achieve Their Dream Outcome
                                </strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Could be staying warm in negative temperatures, feeling sexy in their outfit, losing weight, easier to harvest honey, etc.
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked=""
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>
                                    There is text to support your claim that they will achieve their dream outcome.
                                </strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Ex. A study proving weight loss, a description of the technology that makes honey harvesting easy.
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked=""
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>
                                    There are images to support your claim that they will achieve their dream outcome.
                                </strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Ex. A movie star wearing your clothes, a before and after picture showing weight loss.
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked=""
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>
                                    There are videos to support your claim that they will achieve their dream outcome.
                                </strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Ex. A video of someone wearing your jacket comfortably in freezing weather, a video of the beehive having honey extracted easily.
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked=""
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>You guarantee their dream outcome or their money back</strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Not just satisfaction guaranteed, tie it to their outcome. Ex. If you feel cold in -20 degree weather, its yours for free. If you don't lose 10 lbs in 30 days, get a full refund. Harvest a hive in less than 10 minutes or get $50 on us.
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3">
                            <input
                                class="form-check-input flex-shrink-0"
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked=""
                            />
                            <span class="pt-1 form-checked-content">
                                <strong>Reviews that highlight the outcome.</strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Get people to review your product that highlights the story again. Just add more proof! "I would've died of hypothermia, but this jacket saved me.", "I dropped 100lbs with this!!","My beekeeping operation has doubled thanks to you."
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3 bg-body-tertiary">
                            <input
                                class="form-check-input form-check-input-placeholder bg-body-tertiary flex-shrink-0 pe-none"
                                disabled=""
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked=""
                            />
                            <span class="pt-1 form-checked-content">
                            <strong>
                                    Make their dream come true quickly!
                                </strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    Start losing weight in just 30 days, Order today and have it in your hands tomorrow, etc.
                                </small>
                            </span>
                        </label>
                        <label class="list-group-item d-flex gap-3 bg-body-tertiary">
                            <input
                                class="form-check-input form-check-input-placeholder bg-body-tertiary flex-shrink-0 pe-none"
                                disabled=""
                                type="checkbox"
                                value=""
                                style={{ fontSize: '1.375em' }}
                                checked=""
                            />
                            <span class="pt-1 form-checked-content">
                            <strong>
                                    Give them a reason to buy now.
                                </strong>
                                <small class="d-block text-body-secondary">
                                    <svg
                                        class="bi me-1"
                                        width="1em"
                                        height="1em"
                                    ></svg>
                                    "Winter is just around the corner, buy today and get it before the first storm.", "20% until the end of the day!", "We only have 5 left in stock, buy before we run out."
                                </small>
                            </span>
                        </label>
                        
                    </div>
                </div>
                
                <p>We know its a lot to take on, if you need help, reach out and we can take it on for you!</p>
                    <Button
                        type="success"
                        href="https://calendly.com/jeremy-thiesen1/meetings"
                        target="_blank"
                    >
                        Schedule Time With Us
                    </Button>
                </Container>
            </Loading>
        );
    }
}
