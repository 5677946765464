import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';
import CTA from './cta.js';

export default class UseAGuaranteeToImproveConversions extends Component {

    render() {

        return (
            <div class='container'>

                    <h1>
                        Use A Guarantee To Improve Your Conversion Rate
                    </h1>

                        <p class="lead mb-4">
                        Earlier, we talked about <a href='/ways_to_increase_conversion_rate/' target='_blank'>all the ways to increase your conversion rate.</a>
                        </p>

                        <p class="lead mb-4">
                        And now you are here to increase your conversion rate by offering a great guarantee!

                       </p>

                       <p class="lead mb-4">
                       There are two types of guarantees: A blanket guarantee and a conditional guarantee. 

                       </p>

                       <h5><strong>Blanket Guarantee</strong></h5>

                       <p class="lead mb-4">
                       A blanket guarantee is a guarantee that a customer can take if something doesn’t work for them and it doesn’t depend on whether or not they did something right.
                       </p>

                       <p class="lead mb-4">
                       For example, you often see this as “Satisfaction Guaranteed or return it within 30 days.” or “Lifetime Guarantee”. These are great and reduce the risk for the customer that what they buy won’t work for them.
                       </p>

                       <p class="lead mb-4">
                       However, it is better if you use your guarantee as another way to tell your customers about how you will add value to them. 
                       </p>

                       <p class="lead mb-4">
                       For example, <a href='https://www.geeks2u.com.au/'>Geeks2U</a> improved their conversions by 11% by adding a guarantee, but by 49% when they guaranteed fast and friendly service (one of the key reasons customers liked them). 
                       </p>

                       <h5><strong>Conditional Guarantee</strong></h5>

                       <p class="lead mb-4">
                       Conditional Guarantees work better for products and services where you need the customer to do something to achieve their results. For example, a gym might guarantee you’ll lose weight in 6 weeks if you show up to every class. Or you might guarantee that someone will be able to have a conversation in another language for a language learning class if they come to every class and do their homework. And if they don’t they won’t get the guarantee. 
                       </p>

                       <p class="lead mb-4">
                       These are often super powerful when you want to encourage a customer to do some specific actions in order to achieve their outcome and become an on-going customer. 
                       </p>

                       <p class="lead mb-4">
                       Whichever guarantee you choose, you’ll make it easier for the customer to commit to buying your product because it reduces their risk and shows that you are confident in your product. 
                       </p>


                        

                <CTA />
            </div>
        );
    }
}
